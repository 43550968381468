import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button, Accordion } from 'react-bootstrap';
import Header from '../components/Header';
import styled from 'styled-components';
import Footer from '../components/Footer';
import { IoSpeedometer } from 'react-icons/io';
import { FaCarCrash, FaTachometerAlt, FaPhoneVolume, FaMobileAlt } from 'react-icons/fa';

const NotFound = styled.div`
  margin-top: 200px;
  height: calc(100vh - 200px);
  min-height: 500px;

  img {
    max-width: 100%;
  }

  h3 {
    font-size: 28px;
    margin-bottom: 16px;
    color: #2C2C2C;
  }

  p {
    font-size: 15px;
    line-height: 22px;
    color: #818181
  }

  @media screen and (min-width: 992px) {
    height: calc(100vh - 470px);
    min-height: 700px;

    h3 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

const BtnDiv = styled.div`
  margin-top: 38px;

  .button {
    border-radius: 99px;
    padding: 13px;
    font-weight: bold;
    border: 1px solid #B8B5B5;
    color: #B8B5B5;
    max-width: 350px;
    margin: 10px auto;

    &.dark {
      background: #2C2C2C;
      color: #FFFFFF;
      border: 0;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 50px;
  }
`;

const NotFoundPage = ({ id, mode, setMode }) => {
  let imageStyle = {
		width: '436px',
		height: '190px'
	 };

  return (
    <Container fluid className='p-0'>
      <Header id={id} mode={mode} setMode={setMode}/>
      <NotFound>
        <Container className='align-center'>
          <img style={imageStyle} src='/404/404.png' alt='not found'/>
          <h3>Hey! Where’s my page?</h3>
          <p>The page you’re looking for doesn’t exist. <br/>If you think something is broken, report the issue.</p>

          {/* <BtnDiv>
            <div className='button dark'>Back to Home</div>
            <div className='button'>Report Issue</div>
          </BtnDiv> */}
        </Container>
      </NotFound>
      <Footer/>
    </Container>
  )
}

export default NotFoundPage;

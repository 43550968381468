import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BsFillMoonFill, BsFillSunFill } from 'react-icons/bs';
import { CiSun } from 'react-icons/ci';
import ReactGA from 'react-ga4';

const Parnetship = styled.div`
  border: 1px solid #2C2C2C;
  border-radius: 99px;
  color: #2C2C2C;
  font-weight: bold;
  padding: 5px 20px;
`;

const ModeBtn = styled.div`
  background: #F6F6F6;
  border-radius: 50%;
  color: #B8B5B5;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  cursor: pointer;
`;

const Header = ({ id, mode, setMode }) => {
  const [isScroll, setIsScroll] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground)

    if (id) {
      scrollToElement(id)
    }
  }, [])

  const showBlockchainDoc = () =>{ 
    ReactGA.event({
      category: 'User',
      action: 'view YAS Chain API'
    });

    window.open(
      'http://blockchain.yas.com.hk:801/test',
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  let clickable = {
		cursor: 'pointer'
	};

  let brand = {
		width: '106px',
		height: '16px'
	};

  let customNavBarStyle = {
		transition: '0.25s ease-in-out'
	};

  return (
    <Navbar fixed="top" style={customNavBarStyle} className={`${isScroll ? 'nav-white' : 'nav-transparent'} ${mode}`}>
      <Container fluid className='d-flex justify-content-between'>
        <Navbar.Brand href="/" className="py-0">
          <img src='/homepage/brand.png' style={brand} alt='logo' />
        </Navbar.Brand>
        <Nav className="mx-auto d-none d-lg-flex">
          <Nav.Item>
            <NavLink as={Link} to="/what-is-yasmo" className="nav-link" onClick={() => scrollToElement('what-is-yasmo')}>What is YASMO?</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink as={Link} to="/how-to-play" className="nav-link" onClick={() => scrollToElement('how-to-play')}>How to Play</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink as={Link} to="/roadmap" className="nav-link" onClick={() => scrollToElement('roadmap')}>Roadmap</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink as={Link} to="/scoring-system" className="nav-link">Scoring System</NavLink>
          </Nav.Item>
          {/* <Nav.Item>
            <NavLink as={Link} to="/marketplace" className="nav-link">Marketplace</NavLink>
          </Nav.Item> */}
        </Nav>
        <div className='d-flex'>
        {/* <a href='https://coda.io/d/YASS-Blockchain_dAYIANIZfI1/'> */}
          <Parnetship onClick={showBlockchainDoc} style={clickable}>YAS Chain API</Parnetship>
          {/* {mode === 'light' && <ModeBtn onClick={() => setMode('dark')}><BsFillMoonFill /></ModeBtn>} */}
          {/* {mode === 'dark' && <ModeBtn onClick={() => setMode('light')}><BsFillSunFill /></ModeBtn>} */}
        </div>
      </Container>
      <Container>
        <Nav className="d-flex d-lg-none mobile-nav">
          <Nav.Item>
            <NavLink as={Link} to="/what-is-yasmo" className="nav-link" onClick={() => scrollToElement('what-is-yasmo')}>What is YASMO?</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink as={Link} to="/how-to-play" className="nav-link" onClick={() => scrollToElement('how-to-play')}>How to Play</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink as={Link} to="/roadmap" className="nav-link" onClick={() => scrollToElement('roadmap')}>Roadmap</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink as={Link} to="/scoring-system" className="nav-link">Scoring System</NavLink>
          </Nav.Item>
          {/* <Nav.Item>
            <NavLink as={Link} to="/marketplace" className="nav-link">Marketplace</NavLink>
          </Nav.Item> */}
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header;

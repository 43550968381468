import React from 'react';
import { Container, Nav, Navbar, NavLink } from 'react-bootstrap';
import styled from 'styled-components';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { RiDiscordFill } from 'react-icons/ri';

const FooterDiv = styled.div`
  background: #2C2C2C;
  padding: 25px;
  text-align: center;
  border-radius: 15px 15px 0px 0px;

  .social {
    a {
      color: #B8B5B5;
      border: 0.0488281px solid #B8B5B5;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: inline-block;
      font-size: 26px;
      margin: 20px 10px;
      
      &:hover {
        color: #B8B5B5;
      }
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    color: #818181;
    margin-bottom: 10px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
`;

const Footer = () => {
  let brand = {
		width: '106px',
		height: '16px'
	};

  return (
    <FooterDiv>
      <Container>
        <img style={brand} src='/homepage/brand-w.png' alt='logo' />
      </Container>
    </FooterDiv>
  )
}

export default Footer;

import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button, Accordion } from 'react-bootstrap';
import Header from '../components/Header';
import styled from 'styled-components';
import Footer from '../components/Footer';
import { IoSpeedometer } from 'react-icons/io';
import { FaCarCrash, FaTachometerAlt, FaPhoneVolume, FaMobileAlt } from 'react-icons/fa';
import ReactGA from 'react-ga4';

const System = styled.div`
  padding-top: 150px;

  img {
    max-width: 100%;
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #818181;
  }

  @media screen and (min-width: 992px) {
    padding-top: 100px;

    h1 {
      font-size: 72px;
    }

    p {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

const Score = styled.div`
  border: 1px solid #B8B5B5;
  border-radius: 20px;
  margin-top: 50px;

  .top {
    padding: 20px;   
    
    .bg-dark {
      padding: 13px;
      font-size: 15px;
      font-weight: bold;
      border-radius: 15px;
    }

    .bg-blue {
      background: #CDEEFA;
      color: #38BDED;
    }

    .bg-red {
      background: #FDD4D4;
      color: #F95352;
    }

    .bg-grey {
      background: #EBEBEB;
      border-radius: 20px;
      color: #2C2C2C;
      padding: 20px;
      margin: 20px 0 30px;
      width: 100%;

      p {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }

    .content {
      padding: 20px;
      margin: 20px 0 30px;
      border-radius: 20px;
      width: 100%;

      .title {
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
      }

      .heading {
        color: #2C2C2C;
        font-weight: bold;
        font-size: 15px;
      }
      
      .p {
        color: #818181;
        font-size: 15px;
      }
    }
  }

  .bottom {
    .content {
      padding: 40px 25px;
      font-weight: bold;
      font-size: 28px;
      border: 1px solid black;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  @media screen and (min-width: 992px) {
    .top {
      padding: 30px 50px;   

      .bg-dark {
        padding: 17px;
        font-size: 24px;
      }

      .content {
        .title {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .bottom {
      .content {
        padding: 50px;
        font-size: 40px;
      }
    }
  }
`;

const FAQ = styled.div`
  margin: 50px auto;

  h3 {
    font-weight: bold;
    font-size: 40px;
  }

  .accordion {
    margin-bottom: 50px;

    .accordion-item {
      border: 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 20px 0;
      background: white;
    }

    .accordion-button {
      font-weight: bold; 
      color: black;
      border-radius: 10px;
      font-size: 14px;

      &::after {
        background-image: url('/scoring/plus.png');
        background-size: contain;
      }

      &:not(.collapsed) {
        color: #38BDED;
        background-color: white;
        box-shadow: none;

        &::after {
          background-image: url('/scoring/minus.png');
          background-size: contain;
        }
      }
    }

    .accordion-body {
      color: #6C6C6C;
      font-size: 14px;
    }    
  }

  .button {
    background: #2C2C2C;
    border-radius: 99px;
    padding: 12px;
    text-align: center;
    color: white;
    font-weight: bold;
    max-width: 240px;
    margin: 0 auto 50px;
    cursor: pointer;  
  }
  
  @media screen and (min-width: 992px) {
    max-width: 900px;
    margin: 30px auto;

    .accordion {
      .accordion-button {
        font-size: 16px;
      }

      .accordion-body {
        font-size: 16px;
      }
    }
  }
`;

ReactGA.send({ hitType: "pageview", page: "/scoring-system", title: "Scoring System" });

const ScoringSystem = ({ id, mode, setMode }) => {
  let imageStyle = {
		width: '420px',
		height: '540px',
    maxWidth: '90%',
    objectFit: 'contain'
	 };

  return (
    <Container fluid className='p-0'>
      <Header id={id} mode={mode} setMode={setMode}/>

      <System>
        <Container>
          <div className='align-center'>
            <img style={imageStyle} src='/scoring/mobile.png' alt='mobile' />
            <h1><span className='colored'>Scoring</span> System</h1>
            <p>We believe you are eager to know how we score your driving performance <br/> & how can you score good marks!</p>
          </div>
        </Container>
      </System>

      <Container className='mb-5'>
        <Score>
            <Row className='top m-0'>
              <Col lg={8}>
                <div className='bg-dark align-center'>
                  <div className='d-inline-block colored'>
                    Score Component
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className='bg-dark align-center d-none d-lg-block'>
                  <div className='d-inline-block colored'>
                    Contextual Factors
                  </div>
                </div>
              </Col>

              <Col xs={12}>
                <Row>
                  <Col md={6} lg={4} className='d-flex'>
                    <Row className='m-0 w-100'>
                      <Col className='bg-blue content'>
                        <div className='title'>Driving behaviour score</div>
                        <Row>
                          <Col xs={2} xxl={1}>
                            <FaTachometerAlt />
                          </Col>
                          <Col xs={10} xxl={11}>
                            <div className='heading'>Speeding</div>
                            <div className='p'>Speeding duration, Speeding ratio over limit</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2} xxl={1}>
                            <FaCarCrash />
                          </Col>
                          <Col xs={10} xxl={11}>
                            <div className='heading'>Acceleration, Braking, Cornering</div>
                            <div className='p'>Number of events per km</div>
                          </Col>
                        </Row>
                      </Col>              
                    </Row>
                  </Col>
                  <Col md={6} lg={4} className='d-flex'>
                    <Row className='m-0 w-100'>
                      <Col className='bg-red content'>
                        <div className='title'>Driving focus score</div>
                        <Row>
                          <Col xs={2} xxl={1}>
                            <FaPhoneVolume />
                          </Col>
                          <Col xs={10} xxl={11}>
                            <div className='heading'>Incoming/Outgoing Call Penalty</div>
                            <div className='p'>Call duration</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2} xxl={1}>
                            <FaMobileAlt />
                          </Col>
                          <Col xs={10} xxl={11}>
                            <div className='heading'>Phone Handling/Fiddling Penalty</div>
                            <div className='p'>Number of events per km</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4} className='d-flex flex-wrap'>
                    <div className='bg-dark align-center d-lg-none w-100'>
                      <div className='d-inline-block colored'>
                        Contextual Factors
                      </div>
                    </div>
                    <div className='bg-grey align-center d-flex justify-content-center align-items-center'>
                      <div>
                        <p>Speed Limit</p>
                        <p>Day/Night Driving</p>
                        <p>Rush Hour</p>
                        <p>Fatigue Driving</p>                      
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='bottom m-0'>
              <Col className='bg-dark align-center content'>
                <div className='d-inline-block colored'>Your Final Driving Score</div>
              </Col>
            </Row>
        </Score>        
      </Container>

      {/* <Container>
        <FAQ>
          <h3 className='align-center'>FAQ</h3>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is YAS?</Accordion.Header>
              <Accordion.Body>
                You may be wondering who we are? YAS is a MicroInsurance company founded on the idea that insurance should be simple, efficient, and made for the customer. RYDE with YAS is just one of many ideas we’re working on right now. We’ll be announcing more insurance products in the future that are flexible and real-time. Let us know your feedback and help us reshape insurance!
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>What is real-time microinsurance?</Accordion.Header>
              <Accordion.Body>
                You may be wondering who we are? YAS is a MicroInsurance company founded on the idea that insurance should be simple, efficient, and made for the customer.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>What is real-time microinsurance?</Accordion.Header>
              <Accordion.Body>
                RYDE with YAS is just one of many ideas we’re working on right now. We’ll be announcing more insurance products in the future that are flexible and real-time. 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>What is real-time microinsurance?</Accordion.Header>
              <Accordion.Body>
                Let us know your feedback and help us reshape insurance!
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>

          <div className='button'>Whitepaper</div>
        </FAQ>
      </Container> */}
      <Footer/>
    </Container>
  )
}

export default ScoringSystem;

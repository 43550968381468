import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Header from '../components/Header';
import styled from 'styled-components';
import Footer from '../components/Footer';
import { RiSoundModuleLine } from 'react-icons/ri';
import axios from 'axios';
import ReactGA from 'react-ga4';

const Home = styled.div`
  background: url('/homepage/bg-m.png') no-repeat no-repeat;
  background-size: cover;
  height: 100vh;
  
  .vertically-center {
    margin: auto;
  }

  h1 {
    color: #2C2C2C;
    font-size: 48px;
    font-weight: bold;
  }

  h2 {
    display: inline-block;
    font-size: 48px;
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
    color: #818181;
  }

  h4 {
    font-size: 15px;
    color: #818181;
  }

  a {
    margin-top: 50px;
    display: block;
  }

  @media screen and (min-width: 992px) {
    background: url('/homepage/bg.png') no-repeat no-repeat;
    background-size: cover;

    h1 {
      color: #2C2C2C;
      font-size: 72px;
      font-weight: bold;
    }

    h2 {
      display: inline-block;
      font-size: 72px;
      font-weight: bold;
    }

    h3 {
      font-weight: bold;
      font-size: 24px;
      color: #818181;
    }

    h4 {
      font-size: 20px;
      color: #818181;
    }

    a {
      margin-top: 50px;
      display: block;
    }
  }
`;

const WhatIs = styled.div`
  background-color: #2C2C2C;
  min-height: 600px;

  .container {
    padding: 100px 12px;
  }

  .title-div {
    .title {
      font-weight: bold;
      font-size: 64px;
      display: inline-block;
      margin-bottom: 16px;
      line-height: 70px;
    }

    @media screen and (min-width: 992px) {
      text-align: right;

      .title {
        margin: 0 20px;
        text-align: center;
      }
    }
  }

  
`;

const HowTo = styled.div`
  background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%), #FAFAFA;

 .container {
    padding: 100px 12px;
  }

  .col {
    flex-grow: 1;
  }

  .title {
    display: inline-block;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .download {
    font-size: 24px;
    font-weight: bold;
    color: #2C2C2C;
    margin: 50px 0 20px;
  }
`;

const StepCard = styled.div`
  position: relative;
  background: #F5F5F5;
  border-radius: 20px;
  padding: 20px 30px 362px;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  .step {
    font-size: 12px;
    font-weight: bold;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 90%;
    width: 240px;
  }

  p {
    color: #818181;

    b {
      color: black;
    }
  }
`;

const Roadmap = styled.div`
  img {
    width: 100%;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 42px;
    display: inline-block;
    margin-top: 48px;
    margin-bottom: 16px;
  }

  .timeline {
    width: 0;
    height: 100%;
    border: 5px solid;
    border-image: linear-gradient(to bottom, #FFFFFF 0%, #38BDED 25.02%, #0CE19B 50.05%, #F95352 75.07%, #FFFFFF 100.09%) 1 100%;
  }

  .year {
    font-size: 15px;
    font-weight: bold;
  }

  li {
    color: #818181;
    font-size: 13px;
  }
`;

const JoinWaiting = styled.div`
  .form {
    background: linear-gradient(110.65deg, #38BDED 0%, #0CE19B 58.22%, #FFFFFF 111.79%);
    border-radius: 20px;
    margin: 100px 15px;
    padding: 15px 30px;
    position: relative;

    .title {
      font-size: 28px;
      line-height: 60px;
      font-weight: bold;
      color: white;
    }

    p {
      font-size: 15px;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.75);
      margin: 0px;
    }

    .form-control {
      background: rgba(255, 255, 255, 0.25);
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      color: white;
      margin: 20px auto;
      max-width: 600px;

      &::placeholder {
        color: white;
        opacity: 1;
      }
    }

    .btn-primary {
      background: #FFFFFF;
      border-radius: 99px;
      color: #0CE19B;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 10%;
      border: none;
    }

    @media screen and (min-width: 992px) {
      width: 70%;
      max-width: 1000px;
      margin: 100px auto;
      padding: 4rem 15%;

      .title {
        font-size: 40px;
      }

      p {
        font-size: 20px;
      }
    }
  }

  /* 
  * Provided by the Generator. 
  * This is what creates the shadow.
  */
  .form:before {
    border-radius: 20px;
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(110.65deg, #38BDED 0%, #0CE19B 58.22%, #FFFFFF 111.79%);
    transform: translate3d(0px, 20px, 0) scale(0.95);
    filter: blur(20px);
    opacity: var(0.7);
    transition: opacity 0.3s;
  }

  /* 
  * Provided by the Generator.
  * Prevents issues when the parent creates a 
  * stacking context. (For example, using the transform
  * property )
  */
.form::after {
    border-radius: 20px;
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
}
`;

ReactGA.send({ hitType: "pageview", page: "/homepage", title: "Homepage" });

const Homepage = ({ id, mode, setMode }) => {
  const [email, setEmail] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: 'User',
      action: 'register wait list'
    });

    axios.post("https://asia-east2-projectmerlin-d86d1.cloudfunctions.net/api/waiting-list", {email}).then((response) => {
      if (response.data.status === 'success') {
        alert('Joined successfully!')
      } else {
        alert('Failed to join, please try again later!')
      }
    })
  }

  let appStoreLinkStyle = {
		width: '180px',
		height: '60px'
	 };

  useEffect(() => {
    // console.log(mode)
  }, [])
  return (
    <Container fluid className='p-0'>
      <Header id={id} mode={mode} setMode={setMode}/>

      <Home>
        <Container className='d-flex align-items-center h-100'>
          <div>
            <h1>Drive safer and smarter</h1>
            <h2 className='colored'>with YASMO</h2>
            <h3>a web3 insurance wallet storing insurance policy and usage data</h3>
            <h4>Download now and experience the future of driving using exclusive promo code <b className='colored title'>MAXCSR</b>!</h4>
            <a href='https://apps.apple.com/hk/app/yasmo/id1633180916?l=en'><img style={appStoreLinkStyle} src='/homepage/app-store.png' alt='Download' /></a>
          </div>
        </Container>
      </Home>

      <WhatIs id='what-is-yasmo'>
        <Container>
          <Row>
            <Col xs={12} lg={4}>
              <div className='title-div'>
                <div className='colored title'>What is <br />YASMO?</div>
              </div>
            </Col>
            <Col className='text-white content' xs={12} lg={8}>
              <p>YASMO(beta) is <b>a web3 insurance wallet storing insurance policies and usage data</b> in a transparent and secure manner on the blockchain. With YASMO, users have complete control over their personal data and usage data, as they can choose to share it with insurers to get quotes. By utilizing the blockchain, the YASMO wallet ensures data integrity and tamper-proof protection of sensitive data.</p>
              <p>YASMO uses telematics and data on the chain to provide users with <b>accurate and trustworthy data about their driving habits</b>. By analyzing telematics data such as speed, location, and time of day, YASMO can offer personalized insurance policies that fit the user's specific driving habits. With the use of smart contracts and NFTs, YASMO can provide a rewards system that allows brands, games, and government entities to sponsor web3.0 assets for good driving.</p>
              <p>YASMO <b>provides an open API on the chain for insurance and merchant integration</b>. This API allows for easy integration with insurance companies and merchants, enabling a seamless experience for users. With this integration, insurance companies can offer personalized quotes based on the user's driving habits and data, while merchants can offer rewards and incentives for good driving behavior. The use of smart contracts and NFTs ensures data integrity and provides a transparent system for all parties involved.</p>
            </Col>
          </Row>
        </Container>
      </WhatIs>

      <HowTo id='how-to-play'>
        <Container>
          <div className='align-center'>
            <div className='colored title'>How to Play</div>
          </div>
          <Row>
            <Col xs={12} md={6} lg={3} className='mb-3'>
              <StepCard>
                <div className='step'>Step 1</div>
                <div className='title'>Create a Wallet</div>
                <p>Join your friends at YASMO with a promo code. You can then be part of the engaged driving community that promotes safer driving habits!</p>
                <img src='/homepage/step1.png' alt='step 1' />
              </StepCard>
            </Col>

            <Col xs={12} md={6} lg={3} className='mb-3'>
              <StepCard>
                <div className='step'>Step 2</div>
                <div className='title'>Drive & Compete</div>
                <p>Tap “<b>Start Trip</b>” or simply switch on the Autopilot mode, your driving performance will then be measured. Compete with your friends and top players around the world!</p>
                <img src='/homepage/step2.png' alt='step 2' />
              </StepCard>
            </Col>

            <Col xs={12} md={6} lg={3} className='mb-3'>
              <StepCard>
                <div className='step'>Step 3</div>
                <div className='title'>Secure your Data</div>
                <p>Your sensitive data is safely written on our blockchain. We are the first Web3 app storing telematics insurance data on chain.</p>
                <img src='/homepage/step3.png' alt='step 3' />
              </StepCard>
            </Col>

            <Col xs={12} md={6} lg={3} className='mb-3'>
              <StepCard>
                <div className='step'>Step 4</div>
                <div className='title'>Minting as you go</div>
                <p>In our diverse marketplace, not only you can mint rewards from our partners, but also insurance policies, and even turning your data into tradable items.</p>
                <img src='/homepage/step4.png' alt='step 4' />
              </StepCard>
            </Col>
          </Row>
          
          <div className='align-center'>
            <div className='download'>Download now and experience the future of driving using exclusive promo code <b>MAXCSR</b>!</div>
            <a href='https://apps.apple.com/hk/app/yasmo/id1633180916?l=en'><img style={appStoreLinkStyle} src='/homepage/app-store.png' alt='logo' /></a>
          </div>
        </Container>
      </HowTo>

      <Roadmap id='roadmap'>
        <img src='/homepage/roadmap.png' alt='Roadmap' className='d-none d-lg-block'/>
        <Container className='d-lg-none'>
          <div className='align-center'>
            <div className='colored title'>Roadmap</div>
          </div>
          <Row>
            <Col xs={{span: 1, offset: 1}} md={{span: 1, offset: 2}}>
              <div className='timeline'/>
            </Col>
            <Col xs={9} md={7}>
              <div>
                <div className='year'>Q2 2022</div>
                <ul>
                  <li>Develop an in-house green blockchain</li>
                  <li>Integrate telematics data into our blockchain</li>
                </ul>
              </div>
              <div>
                <div className='year'>Q1 2023</div>
                <ul>
                  <li>Global launch the iOS app</li>
                  <li>Marketplace is online</li>
                </ul>
              </div>
              <div>
                <div className='year'>Q2 2023</div>
                <ul>
                  <li>Provide API for insurance companies</li>
                  <li>Provide API for merchants</li>
                </ul>
              </div>
              <div>
                <div className='year'>Q1 2024</div>
                <ul>
                  <li>Implement underwriting models on chain</li>
                  <li>Offer autonomous insurance</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Roadmap>

      <JoinWaiting>
        <div className='form align-center'>
          <div className='title'>Join the waiting list</div>
          <p>Don’t have a referral code? </p>
          <p>Get early access by leaving us your email.</p>
          <Form>
            <Form.Control type='email' name='email' placeholder='Your email address' onChange={(e) => setEmail(e.target.value)}/>
            <Button type='submit' onClick={(e) => submitForm(e)}>Get Early Access</Button>            
          </Form>
        </div>  
      </JoinWaiting>
      
      <Footer />
    </Container>
  )
}

export default Homepage;

import React, { useState, useEffect } from 'react';
import './App.css';
import Homepage from './pages/Homepage';
import { Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ScoringSystem from './pages/ScoringSystem';
import NotFoundPage from './pages/404';

function App() {
  const [mode, setMode] = useState(localStorage.getItem('mode') || 'light');

  useEffect(() => {
    // console.log(mode)
  }, [])

  return (
    <Container fluid className='p-0'>
      <Routes>
        <Route path="/" element={<Homepage id={null} mode={mode} setMode={setMode} />} />
        <Route path="/what-is-yasmo" element={<Homepage id='what-is-yasmo' mode={mode} setMode={setMode} />} />
        <Route path="/how-to-play" element={<Homepage id='how-to-play' mode={mode} setMode={setMode} />} />
        <Route path="/roadmap" element={<Homepage id='roadmap' mode={mode} setMode={setMode} />} />
        <Route path="/scoring-system" element={<ScoringSystem id={null} mode={mode} setMode={setMode} />} />
        <Route path="/marketplace" element={<NotFoundPage id={null} mode={mode} setMode={setMode} />} />
      </Routes>
    </Container>
  );
}

export default App;
